import styled from 'styled-components';
import Img from 'gatsby-image';
import IndexLayout from '../layouts';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link, graphql } from 'gatsby';
import { AppTitle, NavBar, TextCycler, Wrapper, AcceptCookies } from '../components/ui/';
import {
  BottomNavSegment,
  Footer,
  IndexHeroSegment,
  IndexMissionSection,
  IndexCompanySegment,
  IndexOurPartnersSegment,
  IndexOurTeamSegment,
  NewSplashScreen,
} from '../components/PageSegments/';
import favicon from '../content/img/icon/favicon.ico';
import siteIcon from '../content/img/icon/base10_favicon.png';

export default ({ data }) => {
  return (
    <IndexLayout>
      <Helmet>
        <title>Home - Base10</title>
        <link rel="stylesheet" type="text/css" href="/global.css" />
        <link rel="icon" href={favicon} />
        <meta name="description" content="Investing in Automation for the Real Economy" />
        <html lang="en-US" />
        <meta name="theme-color" content="#1a1a1a" />
        <link rel="canonical" href="https://base10.vc/" />
        <meta charSet="UTF-8" />
      </Helmet>
      <Main>
        <Wrapper>
          <NavBar invert anchorToHere />
        </Wrapper>
        <NewSplashScreen />
        {/* <IndexHeroSegment />
        <IndexMissionSection />
        <IndexCompanySegment />
        <IndexOurTeamSegment />
        <IndexOurPartnersSegment />
        <Footer /> */}
        <BottomNavSegment />
        {/* <AcceptCookies /> */}
      </Main>
    </IndexLayout>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* height: 2000px; */
  /* background: grey; */
`;
